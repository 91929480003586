<template>
  <card-layout
    v-if="!box || box.loading || skeleton"
    :landscape="landscape">
    <card-picture
      :landscape="landscape"
      skeleton />

    <card-body :landscape="landscape">
      <card-locality skeleton />
      <card-title skeleton />

      <template #footer>
        <card-box-price skeleton />
        <card-box-tools
          v-if="!readOnly"
          class="absolute bottom-0 right-0"
          skeleton />
      </template>
    </card-body>
  </card-layout>

  <card-layout
    v-else
    :box="box"
    :landscape="landscape"
    :redirect="redirect">
    <card-picture
      :extra-img-class="extraImgClass"
      :landscape="landscape"
      :no-lazy-load="noLazyLoad"
      :src="isTemporaryOrder ? 'placeholder-temporary-order' : box.image">
      <card-box-overlay
        v-if="!replace && !readOnly"
        :box="box"
        :quantity="nbItems">
        <template v-if="!order">
          dans mon panier
        </template>
      </card-box-overlay>

      <card-box-tags
        v-if="tags && tags.length"
        :tags="tags" />

      <card-box-labels
        v-if="box?.labels?.length"
        :labels="box.labels" />
    </card-picture>

    <card-body :landscape="landscape">
      <card-locality
        v-if="box"
        :box="box"
        :small="small" />

      <card-title
        :title="getSupplierName ? box.name + ' de ' + getSupplierName : box.name"
        class="font-semibold">
        {{ box.name }}

        <span
          v-if="getSupplierName"
          class="font-normal">
          de {{ getSupplierName }}
        </span>
      </card-title>

      <template #footer>
        <card-box-price
          :box="box"
          :context="context"
          :order="order"
          :show-sub-price="showSubPrice" />

        <card-box-tools
          v-if="!readOnly && !replace"
          :box="box"
          :disabled="box.isAssociableToVouchers"
          :order="order"
          :quantity="nbItems"
          class="absolute bottom-0 right-0" />

        <div
          v-if="$slots.footer"
          class="pt-1.5">
          <slot name="footer" />
        </div>
      </template>
    </card-body>

    <slot name="layout-after" />
  </card-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import BasketActionMixin from 'Mixins/BasketActionMixin';
import ShadowBasketActionMixin from 'Mixins/ShadowBasketActionMixin';

import { ABONNEMENT } from 'Classes/OrderStatus';
import { calcSlug } from 'Classes/utils/RouteUtils';

import { getBoxSuppliers } from 'PotagerLogic/Utils/Box/BoxSuppliers';
import { Context } from 'PotagerLogic/Enums/Context';

import CardBody from 'Components/cards/UI/CardBody';
import CardBoxLabels from 'Components/cards/UI/CardBoxLabels';
import CardBoxOverlay from 'Components/cards/UI/CardBoxOverlay';
import CardBoxPrice from 'Components/cards/UI/CardBoxPrice';
import CardBoxTags from 'Components/cards/UI/CardBoxTags';
import CardBoxTools from 'Components/cards/UI/CardBoxTools';
import CardLayout from 'Components/cards/UI/CardLayout';
import CardLocality from 'Components/cards/UI/CardLocality';
import CardPicture from 'Components/cards/UI/CardPicture';
import CardTitle from 'Components/cards/UI/CardTitle';

export const props = {
  box: {
    type: Object,
    required: false,
    default: null,
  },
  landscape: {
    type: Boolean,
    required: false,
    default: false,
  },
  order: {
    type: Object,
    required: false,
    default: null,
  },
  productIdToReplace: {
    type: [String, Number],
    required: false,
    default: null,
  },
  isTemporaryOrder: {
    type: Boolean,
    required: false,
    default: false,
  },
  replace: {
    type: Boolean,
    required: false,
    default: false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  noLazyLoad: {
    type: Boolean,
    required: false,
    default: false,
  },
  extraImgClass: {
    type: String,
    required: false,
    default: '',
  },
  overlay: {
    type: Boolean,
    required: false,
    default: false,
  },
  skeleton: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
  context: {
    type: String,
    default: undefined,
    validator: (value) => Object.values(Context)
      .includes(value),
  },
};

export default {
  mixins: [
    BasketActionMixin,
    ShadowBasketActionMixin,
  ],

  components: {
    CardBody,
    CardBoxLabels,
    CardBoxOverlay,
    CardBoxPrice,
    CardBoxTags,
    CardBoxTools,
    CardLayout,
    CardLocality,
    CardPicture,
    CardTitle,
  },

  props,

  computed: {
    ...mapGetters('basket', [
      'getProducts',
      'isSubscription',
    ]),
    redirect() {
      if (!this.isTemporaryOrder && !this.readOnly) {
        const productIdToReplace = this.$potagerRoute.params.productIdToReplace || this.productIdToReplace;
        return !this.replace || productIdToReplace
          ? {
            name: this.replace ? 'replace-detail-product-item' : 'detail-product-item',
            params: {
              slug: calcSlug(this.box.name),
              productIdToReplace,
              id: this.box.id,
              idRegion: this.box.region?.id,
              orderId: this.order?.id,
            },
          }
          : null;
      }
      return null;
    },
    showSubPrice() {
      if (!!this.order) return this.order.canal === ABONNEMENT || this.isTemporaryOrder;
      return this.isSubscription;
    },
    nbItems() {
      if (this.isTemporaryOrder) return null;
      const boxes = !this.order ? this.getProducts : this.order.boxes;
      if (boxes) return boxes.filter((box) => box.id === this.box.id)?.length;
      return null;
    },
    isFormule() {
      return this.box.isTypeFormule;
    },
    getSupplierName() {
      return this.box.isComplementary ? getBoxSuppliers(this.box) : null;
    },
    tags() {
      return this.box.tags?.filter((tag) => tag.type !== 'primary');
    },
  },
};
</script>
