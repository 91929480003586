<template>
  <potager-button
    :is="to || onClick ? 'potager-button' : 'div'"
    :class="[
      'panel-section__navigator text-left w-full flex items-center justify-between',
      { 'cursor-default outline-0 shadow-none': !(to || onClick) }
    ]"
    :ripple="!!to"
    :to="to"
    multi-line
    theme="reset"
    @onClick="onClick">
    <template
      v-if="$slots.left"
      #icon>
      <slot name="left" />
    </template>

    <span class="panel-section__navigator-text">
      <slot />
    </span>

    <template #right>
      <slot name="right">
        <span
          v-if="to || onClick"
          :class="[
            'panel-section__navigator-arrow',
            'potager-button potager-button--circle potager-button--size-small potager-button--outlined potager-button--stroke',
          ]">
          <icon-arrow-right />
        </span>
      </slot>
    </template>
  </potager-button>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import IconArrowRight from 'Icons/bold/IconArrowRight';

export default {
  name: 'PanelSectionNavigator',
  components: {
    PotagerButton,
    IconArrowRight,
  },
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
};
</script>
