import store from 'Stores';
import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';

import { getGddError } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDayErrors';
import WORDINGS from 'PotagerLogic/Constants/Wordings';

export const BT004_ADD_BOX_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  text: WORDINGS.GDD_DELETE,
  closeBtnText: 'Modifier mon point de retrait',
  onCloseRedirect: getDlpSearchRoute(),
  showCancel: true,
  handle(app, response, error) {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    error.text = getGddError(gdd);
  },
};

export default {
  default: {
    text: WORDINGS.GDD_DELETE,
  },
  'UserApi.addBox': BT004_ADD_BOX_ERROR,
  'TemporaryCustomerApi.addBox': BT004_ADD_BOX_ERROR,
};
