import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { formatPrice } from 'PotagerLogic/Formatting';

import { couponUnit } from 'Classes/utils/CouponsUtils';

/**
 * Get the order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The order line object or null if no order.
 */
export const getOrderLine = (order) => (order ? {
  label: 'Votre commande',
  value: `${formatPrice(getOrderPrice(order)?.subTotal)}`,
} : null);

/**
 * Get the completion order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The completion order line object or null if no order.
 */
export const getCompletionOrderLine = (order) => (order ? {
  label: 'Votre panier en cours',
  value: `${formatPrice(getOrderPrice(order)?.subTotal)}`,
} : null);

/**
 * Get the linked order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The linked order line object or null if no order.
 */
export const getLinkedOrderLine = (order) => (order ? {
  label: 'Votre commande en cours',
  value: `${formatPrice(getOrderPrice(order)?.subTotal)}`,
} : null);

/**
 * Get the shipping cost line for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Object} [order.deliveryPoint] - The delivery point object.
 * @param {boolean} [order.deliveryPoint.hasShippingCost] - Whether the delivery point has a shipping cost.
 * @returns {Object} The shipping cost line object.
 */
export const getShippingCostLine = (order) => {
  const cost = getOrderPrice(order)?.shippingCost;

  return {
    label: 'Livraison',
    value: formatPrice(cost, { freeString: 'Offerte' }),
    type: !parseFloat(cost) ? 'discount' : undefined,
  };
};

/**
 * Get the enterprise discount line for an order.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The enterprise discount line object or null if no discount.
 */
export const getEntrepriseDiscountLine = (order) => {
  const discount = parseFloat(getOrderPrice(order)?.enterpriseDiscount);
  return discount !== 0 ? {
    label: 'Remise entreprise',
    value: `-${formatPrice(discount)}`,
    type: 'discount',
  } : null;
};

/**
 * Get the list of coupon lines for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Array<Object>} order.coupons - The list of coupons applied to the order.
 * @param {string} order.coupons[].couponCode - The code of the coupon.
 * @returns {Array<Object>} The list of coupon lines.
 */
export const getCouponsListLines = (order) => {
  return order.coupons
    .map((coupon) => ({
      label: coupon.couponCode,
      value: couponUnit(coupon),
      type: 'discount',
    }));
};

/**
 * Get the total line for an order.
 *
 * @param {Object} order - The current order object.
 * @returns {Object} The total line object.
 */
export const getTotalLine = (order) => {
  return {
    label: 'Total',
    value: `${formatPrice(getOrderPrice(order)?.total)}`,
    type: 'total',
  };
};

/**
 * Get the receipt lines for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Object} [options] - Additional options.
 * @param {Object} [options.linkedOrder] - The linked order object.
 * @param {Object} [options.completionOrder] - The completion order object.
 * @param {boolean} [options.showCoupons=false] - Whether to show coupon lines.
 * @returns {Array<Object>} The list of receipt lines.
 */
export const getReceiptLines = (order, {
  linkedOrder,
  completionOrder,
  showCoupons = false
} = {}) => {
  if (!order) return [];

  const lines = linkedOrder && completionOrder
    ? [getCompletionOrderLine(completionOrder), getLinkedOrderLine(linkedOrder)]
    : [getOrderLine(order)];

  return [
    ...lines,
    ...(showCoupons ? getCouponsListLines(order) : []),
    getShippingCostLine(order),
    getEntrepriseDiscountLine(order)
  ].filter(Boolean);
};
