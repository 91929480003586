<template>
  <notifications
    ref="notifications"
    :duration="10000"
    :position="$mq.bp768 ? 'top center' : 'top right'"
    class="notifications potager-container potager-container--medium-small">
    <template #body="props">
      <div
        class="w-full bg-white/[.75] rounded backdrop-blur mt-2.5 text-xs.5 overflow-hidden max-w-[420px]">
        <div class="p-4">
          <potager-button
            class="absolute top-2 right-2"
            is-circle
            size="small"
            theme="transparent"
            @onClick="props.close">
            <template #icon>
              <icon-close class="text-sm" />
            </template>
          </potager-button>

          <p
            v-if="props.item.title"
            class="text-sm font-bold pr-12">
            <span
              v-if="props.item.type"
              class="mr-1">
              {{ getIcon(props.item.type) }}
            </span>

            <span v-html="props.item.title" />
          </p>

          <div
            v-if="props.item.text"
            class="notification__text"
            v-html="props.item.text" />

          <div
            v-if="props.item.data && props.item.buttons"
            class="notification__btn-group">
            <template v-for="(button, index) in props.item.buttons">
              <potager-button
                v-if="button.onClick"
                :key="`notification-button-${index}`"
                :is-loading="$wait.is(button.isLoading)"
                :label="button.label"
                :theme="button.theme"
                @onClick="onClick(button, props)" />

              <potager-button
                v-else-if="button.redirect"
                :key="`notification-button-${index}`"
                :is-loading="$wait.is(button.isLoading)"
                :label="button.label"
                :theme="button.theme"
                :to="button.redirect"
                @click="props.close" />
            </template>
          </div>
        </div>

        <!-- 599 because it's default value for notification length -->
        <div
          v-if="props.item.length > 599"
          class="w-full">
          <div
            :class="`notification__progress-bar notification__progress-bar--${props.item.type || 'success'}`"
            :style="{ animationDuration: props.item.length + 'ms' }" />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import IconClose from 'Icons/bold/IconClose';
import { MessageType } from 'PotagerLogic/Enums/Messages';

export default {
  components: {
    PotagerButton,
    IconClose,
  },

  methods: {
    onClick(button, props) {
      if (!button.waitBeforeClosing) props.close();

      Promise.resolve(button.onClick.bind(this)())
        .then(() => {
          props.close();
        });
    },
    getIcon(type) {
      switch (type) {
        case MessageType.Error:
          return '❌';
        case MessageType.Warning:
          return '⚠️';
        case MessageType.Success:
          return '✅';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  padding: 0 10px;
  justify-content: flex-end;
  height: 0;
  width: 100% !important;
  position: relative;
  display: flex;
  left: 0 !important;

  :deep() > span {
    display: block;
    width: 100%;
    max-width: 420px;
  }

  @include bp1024 {
    justify-content: center;
  }
}

.notification {
  &__btn-group {
    margin-top: 1rem;
    display: flex;

    @include bp480 {
      flex-direction: column;
    }

    > * {
      width: 100%;

      + * {
        margin-left: .5rem;

        @include bp480 {
          margin-left: 0;
          margin-top: .5rem;
        }
      }
    }
  }

  &--maintenance {
    background: $charcoal;
    color: $white;
  }

  &__progress-bar {
    animation-fill-mode: forwards;
    animation-name: progress;
    animation-timing-function: linear;
    height: 2px;

    &--success {
      background: $goGreen;
    }

    &--warning {
      background: $zest;
    }

    &--error {
      background: $monza;
    }

    @keyframes progress {
      0% {
        width: 100%;
      }
      100% {
        width: 0;
      }
    }
  }
}
</style>
