import COLORS from 'PotagerLogic/Enums/Colors';
import ConfigurationManager from 'Classes/ConfigurationManager';

import markerEnable from 'potagerassets/dist/images/marker-enable.svg';
import markerEnableActive from 'potagerassets/dist/images/marker-enable-active.svg';
import markerUser from 'potagerassets/dist/images/marker-user.svg';

const { forestGreen } = COLORS;

export const COUNTRY_CENTER = {
  lat: 46.227638,
  lng: 2.213749,
};

export const DEFAULT_ZOOM_LEVEL = 14;
export const DEFAULT_FOCUS_ZOOM_LEVEL = 16;
export const DEFAULT_MIN_ZOOM_LEVEL = 6;
export const DEFAULT_MAX_ZOOM_LEVEL = 22;
export const DEFAULT_MAP_OPTIONS = {
  zoom: DEFAULT_MIN_ZOOM_LEVEL,
  center: COUNTRY_CENTER,
  styles: [
    {
      featureType: 'poi',
      stylers: [
        { visibility: 'off' },
      ],
    },
  ],
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: 'roadmap',
  maxZoom: DEFAULT_MAX_ZOOM_LEVEL,
  minZoom: DEFAULT_MIN_ZOOM_LEVEL,
  scrollwheel: true,
  streetViewControl: false,
  zoomControl: !ConfigurationManager.getByName('isMobileTablet'),
  gestureHandling: 'greedy',
};

export const getLatLng = ({
  lat,
  lng
}) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng),
});
const getPoint = (x, y) => ({
  x,
  y
});
const getSize = (width, height) => ({
  height,
  width
});

const markerSize = {
  width: 37,
  height: 41.2,
};
const MARKERS_CONFIG = {
  size: getSize(markerSize.width, markerSize.height),
  anchor: getPoint(markerSize.width / 2, markerSize.height),
};

export const MARKER_DEFAULT = {
  url: markerEnable,
  ...MARKERS_CONFIG,
};

export const MARKER_DEFAULT_SELECTED = {
  url: markerEnableActive,
  ...MARKERS_CONFIG,
};

export const MARKER_DISABLED = {
  // url: '/images/marker-disable.svg',
  // ...MARKERS_CONFIG,
  ...MARKER_DEFAULT,
};

export const MARKER_DISABLED_SELECTED = {
  // url: '/images/marker-disable-active.svg',
  // ...MARKERS_CONFIG,
  ...MARKER_DEFAULT_SELECTED,
};

export const MARKER_USER = {
  url: markerUser,
  size: getSize(64, 64),
  scaledSize: getSize(64, 64),
  origin: getPoint(0, 0),
  anchor: getPoint(32, 32),
};

export const WOOSMAP_DISABLED_DLP_TYPE = (context) => (SubscriptionCon.includes(context) ? 'isGddDisabledForSubscription' : 'isGddDisabled');
export const woosmapStyle = (context) => ({
  breakPoint: DEFAULT_ZOOM_LEVEL - 1,
  default: {
    color: forestGreen,
    icon: MARKER_DEFAULT,
  },
  rules: [
    // {
    //   color: warm-grey,
    //   type: WOOSMAP_DISABLED_DLP_TYPE(context),
    //   icon: MARKER_DISABLED,
    // },
  ],
});
