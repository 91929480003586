import {
  fromUnixTime,
  isBefore,
  isToday
} from 'date-fns';

import { convertAddressToParameter } from 'Classes/utils/DlpUtils';
import { addDaysToTimestamp } from 'PotagerLogic/Utils/Dates/DateCalculation';

import {
  UPDATE_DELIVERY_POINT_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_NEXT_ORDER,
  UPDATE_SUBSCRIPTION_TIME_SLOTS,
  UPDATE_USER,
} from 'Stores/types/userMutationsTypes';

import {
  ADD_SUBSCRIPTION_BREAK_ACTION,
  DELETE_SUBSCRIPTION_BREAK_ACTION,
  GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
  REMOVE_SUBSCRIPTION_ACTION,
  UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION,
  UPDATE_SUBSCRIPTION_BASKET_ACTION,
  UPDATE_SUBSCRIPTION_PRODUCT_ACTION,
} from 'Stores/types/userActionsTypes';

import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';

import { api } from 'Plugins/potagerApiClient';

export default {
  state: {
    subscription: null,
    subscriptionBasket: [],
    subscriptionTimeSlots: [],
  },

  getters: {
    getSubscription: (state) => state.subscription,
    getSubscriptionOrder: (state, getters) => getters.getNextSubscriptionOrder || getters.getNextEditableSubscriptionOrder,
    getSubscriptionDeliveryPoint: (state) => state.subscription?.deliveryPoint,
    getSubscriptionGroupDeliveryDay: (state) => state.subscription?.groupDeliveryDay,
    getSubscriptionAddress: (state) => state.subscription?.address,
    getSubscriptionNextOrder: (state) => state.subscription?.nextOrder,
    getSubscriptionBasket: (state) => state.subscriptionBasket,
    subscriptionTimeSlots: (state, getters) => state.subscription?.status ? state.subscriptionTimeSlots : [],
    getNextDeliveredTimeslot: (state, getters) => (getters.getSubscription ? getters.getSubscription.nextDeliveredTimeslot : null),
    getNextGenerationDate: (state) => state.subscription?.nextGenerationDate,
    isSubscriptionActive: (state) => (state.subscription ? state.subscription.status : false),
    isSubscriptionSuspended: (state) => !state.subscription.nextOrder && state.subscription?.currentSubscriptionBreak && !state.subscription.currentSubscriptionBreak.isPast,
    hasSubscriptionEnterpriseDiscount: (state) => state.subscription?.deliveryPoint?.isEligibleToCompanyDiscount,
    getSubscriptionEnterpriseDiscountValue: (state, getters) => {
      if (!getters.hasSubscriptionEnterpriseDiscount || !state.subscription.product) return 0;
      const sub = parseFloat(state.subscription.product.price.subscription);
      const subEnt = parseFloat(state.subscription.product.price.subscriptionEnterprise);
      return Math.round((sub - subEnt) * 100) / 100;
    },
    isSubscriptionNextOrderCanceled(state, getters) {
      return getters.getSubscriptionNextOrder?.status === 'X';
    },
    getAvailableOrderableDate: (state, getters) => {
      if (getters.isSubscriptionActive) {
        if (getters.isSubscriptionNextOrderCanceled || !getters.getSubscriptionNextOrder) {
          return getters.getNextDeliveredTimeslot.availableOrderableDate;
        }
        return getters.getSubscriptionNextOrder.timeSlot.availableOrderableDate;
      }
      return null;
    },
    isCurrentlyOrderable: (state, getters) => {
      const availableOrderableDate = getters.getAvailableOrderableDate;
      if (!availableOrderableDate) return false;

      const dateFromUnix = fromUnixTime(availableOrderableDate);
      const today = new Date();

      return isToday(dateFromUnix) || isBefore(dateFromUnix, today);
    },
    getLimitOrderDate: (state, getters) => {
      if (getters.isSubscriptionActive) {
        if (getters.isSubscriptionNextOrderCanceled || !getters.getSubscriptionNextOrder) {
          return addDaysToTimestamp(getters.getNextDeliveredTimeslot.date, -2);
        }
        return getters.getSubscriptionNextOrder.limitDate;
      }
      return null;
    },
    getSubscriptionFirstOrderDate: (state, getters) => {
      if (getters.isSubscriptionActive) {
        if (getters.isSubscriptionNextOrderCanceled || !getters.getSubscriptionNextOrder) {
          return getters.getNextDeliveredTimeslot.date;
        }
        return getters.getSubscriptionNextOrder.timeSlot.date;
      }
      return null;
    },
  },

  mutations: {
    [UPDATE_SUBSCRIPTION](state, payload) {
      state.subscription = payload;
    },
    [UPDATE_SUBSCRIPTION_TIME_SLOTS](state, timeSlots) {
      state.subscriptionTimeSlots = timeSlots;
    },
    [UPDATE_SUBSCRIPTION_NEXT_ORDER](state, order) {
      state.subscription.nextOrder = order;
    },
    [UPDATE_DELIVERY_POINT_SUBSCRIPTION](state, payload) {
      state.subscription.deliveryPoint = payload.deliveryPoint;
      state.subscription.groupDeliveryDay = payload.groupDeliveryDay;
      state.subscription.address = payload.address;
    },
  },

  actions: {
    [UPDATE_SUBSCRIPTION_BASKET_ACTION]({
      commit,
      dispatch
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_SUBSCRIPTION_BASKET_ACTION, { root: true });
        api.user.addSubscriptionToSubscriptionBasket(payload.idRegion, payload.idFormule)
          .then((resp) => {
            if (!resp.data.success) reject(resp.data.errors);
            commit(UPDATE_USER, resp.data.data);
            commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_SUBSCRIPTION_BASKET_ACTION, { root: true }));
      });
    },
    [UPDATE_SUBSCRIPTION_PRODUCT_ACTION]({
      commit,
      dispatch
    }, {
      idRegion,
      idFormule,
      address
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_SUBSCRIPTION_PRODUCT_ACTION, { root: true });
        dispatch('wait/start', `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}_${idFormule}`, { root: true });
        api.user.setSubscriptionProduct(idRegion, idFormule, convertAddressToParameter(address))
          .then(({ data }) => {
            if (data.success) {
              commit(UPDATE_USER, data.data);
              commit(UPDATE_SUBSCRIPTION, data.data.subscription);
              resolve(data);
            } else {
              reject(data.errors);
            }
          })
          .catch((data) => reject(data))
          .finally(() => {
            dispatch('wait/end', UPDATE_SUBSCRIPTION_PRODUCT_ACTION, { root: true });
            dispatch('wait/end', `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}_${idFormule}`, { root: true });
          });
      });
    },
    [UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION]({
      commit,
      dispatch
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION, { root: true });
        api.user.setDeliveryPointSubscription(payload.regionId, payload.groupDeliveryDayId, payload.address)
          .then((resp) => {
            commit(UPDATE_USER, resp.data.data);
            commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION, { root: true }));
      });
    },
    [GET_SUBSCRIPTION_TIME_SLOTS_ACTION]({
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_SUBSCRIPTION_TIME_SLOTS_ACTION, { root: true });
        api.subscription.getSubscriptionTimeSlots()
          .then((response) => {
            commit(UPDATE_SUBSCRIPTION_TIME_SLOTS, response.data);
            resolve(response.data);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', GET_SUBSCRIPTION_TIME_SLOTS_ACTION, { root: true });
          });
      });
    },
    [ADD_SUBSCRIPTION_BREAK_ACTION]({
      commit,
      dispatch
    }, date) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_SUBSCRIPTION_BREAK_ACTION, { root: true });
        api.subscription.postSubscriptionBreak(date)
          .then((response) => {
            dispatch('wait/start', GET_SUBSCRIPTION_TIME_SLOTS_ACTION + date, { root: true });
            dispatch(GET_SUBSCRIPTION_TIME_SLOTS_ACTION)
              .finally(() => dispatch('wait/end', GET_SUBSCRIPTION_TIME_SLOTS_ACTION + date, { root: true }));

            const { user } = response.data.data;
            commit(UPDATE_USER, user);
            resolve(response.data.data);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', ADD_SUBSCRIPTION_BREAK_ACTION, { root: true });
          });
      });
    },
    [DELETE_SUBSCRIPTION_BREAK_ACTION]({
      commit,
      dispatch
    }, date) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', DELETE_SUBSCRIPTION_BREAK_ACTION, { root: true });
        api.subscription.deleteSubscriptionBreak(date)
          .then((response) => {
            dispatch('wait/start', GET_SUBSCRIPTION_TIME_SLOTS_ACTION + date, { root: true });
            dispatch(GET_SUBSCRIPTION_TIME_SLOTS_ACTION)
              .finally(() => dispatch('wait/end', GET_SUBSCRIPTION_TIME_SLOTS_ACTION + date, { root: true }));

            const { user } = response.data.data;
            commit(UPDATE_USER, user);
            resolve(response.data.data);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', DELETE_SUBSCRIPTION_BREAK_ACTION, { root: true });
          });
      });
    },
    [REMOVE_SUBSCRIPTION_ACTION]({
      commit,
      dispatch
    }, {
      params,
      simulation
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_SUBSCRIPTION_ACTION, { root: true });
        api.user.removeSubscription(params, simulation)
          .then((response) => {
            if (!simulation) {
              const { user } = response.data.data;
              commit(UPDATE_USER, user);
            }
            resolve(response);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', REMOVE_SUBSCRIPTION_ACTION, { root: true });
          });
      });
    },
  },
};
