import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { SOCIAL_LOGIN_USER_ACTION } from 'Stores/types/sessionActionsTypes';
import { UPDATE_TEMPORARY_CUSTOMER_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';
import { inAppWebView } from 'Classes/ConfigurationManager';
import { capitalize } from 'PotagerLogic/Formatting';

import PotagerProviderButton from 'UI/PotagerProviderButton';
import ModalInfo from 'Modals/ModalInfo';

import { openModal } from 'Plugins/potagerModals';

import { h } from 'vue';

import { useGtm } from '@gtm-support/vue-gtm';
import { notify } from '@kyvg/vue3-notification';

const gtm = useGtm();

export default class {
  constructor(config) {
    this.config = config;
    this.isLoginLoading = false;
    this.isRegisterLoading = false;
  }

  getName() {
    return this.config.name;
  }

  getIcon() {
    return this.config.icon;
  }

  getColors() {
    return this.config.colors;
  }

  getLoginButton(app, callback) {
    const label = `Connexion avec ${capitalize(this.getName())}`;
    const colors = this.getColors();
    const icon = this.getIcon();
    const isLoading = this.isLoginLoading;
    const login = () => this.login(app);
    const theme = 'white';

    return {
      render: () => h(PotagerProviderButton, {
        colors,
        isLoading,
        theme,
        onClick: () => {
          login(app)
            .then((resp) => {
              if (typeof callback === 'function') callback(resp);
            })
            .catch(({ error }) => {
              if (error?.response?.status === 401) {
                openModal(ModalInfo, {
                  title: 'On ne se connaît pas (encore) !',
                  text: 'Vous n\'avez pas encore de compte Potager City avec cette adresse mail. Choisissez votre panier préféré et créez votre compte !',
                  onCloseRedirect: { name: 'tunnel' },
                  closeBtnText: 'Commencer',
                });
              } else {
                console.error(error);

                notify({
                  type: 'error',
                  title: 'Oups. Une erreur est survenue',
                  text: 'Une erreur est survenue lors de la connexion avec votre compte. Merci de réessayer plus tard ou de contacter <a href="https://www.potagercity.fr/contact" class="underline">notre service client</a> si le problème persiste.',
                });
              }
            });
        },
      }, {
        default: () => label,
        icon: () => h(icon)
      }),
    };
  }

  getRegisterButton(app, onSuccess) {
    const label = `S'inscrire avec ${capitalize(this.getName())}`;
    const colors = this.getColors();
    const isLoading = this.isRegisterLoading;
    const icon = this.getIcon();
    const register = (w) => this.register(app, w);
    const theme = 'white';

    return {
      render: () => h(PotagerProviderButton, {
          colors,
          isLoading,
          theme,
          onClick: () => {
            register()
              .then((response) => {
                if (typeof onSuccess === 'function') onSuccess(response);
              });
          },
        },
        {
          default: () => label,
          icon: () => h(icon),
        }),
    };
  }

  tracking(app, value, params = {}) {
    try {
      gtm.trackEvent({
        event: 'Modal',
        eventLabel: `Modal${capitalize(this.getName())}`,
        eventValue: value,
        params,
      });
    } catch (e) { /* Do Nothing */
    }
  }

  signIn(app) {
    return new Promise((resolve, reject) => {
      this.tracking(app, 'open');

      if (inAppWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ socialSignUp: this.getName() }));

        const onResponse = (e) => {
          try {
            const data = JSON.parse(e.data);
            if (data.fromApp) {
              window.removeEventListener('message', onResponse);
              document.removeEventListener('message', onResponse);

              if (data.success) {
                resolve(data.success);
              } else {
                reject();
              }
            }
          } catch (err) { /* Do nothing */
          }
        };

        window.addEventListener('message', onResponse);
        document.addEventListener('message', onResponse);
      } else {
        const providerInstance = this.config.getProviderInstance();
        firebase.auth()
          .signInWithPopup(providerInstance)
          .then((response) => resolve(response))
          .catch((error) => {
            this.tracking(app, 'dismiss');
            reject(error);
          });
      }
    });
  }

  login(app) {
    return new Promise((resolve, reject) => {
      this.startLoginLoading();
      this.signIn(app)
        .then((data) => {
          const param = {
            provider: this.getName(),
            ...this.config.getLoginData(data),
          };
          app.$store.dispatch(`session/${SOCIAL_LOGIN_USER_ACTION}`, param)
            .then((response) => {
              this.tracking(app, 'success', { login: true });
              resolve({
                response,
                data
              });
            })
            .catch((error) => {
              reject({
                error,
                data
              });
            })
            .finally(() => {
              this.endLoginLoading();
            });
        })
        .catch((error) => {
          this.endLoginLoading();
          reject({ error });
        });
    });
  }

  register(app) {
    return new Promise((resolve, reject) => {
      this.startRegisterLoading();
      this.login(app)
        .then(({ response }) => {
          this.endRegisterLoading();
          resolve({
            mode: 'login',
            response,
            provider: this.getName(),
          });
        })
        .catch(({
          error,
          data
        }) => {
          if (error?.response?.status === 401) {
            const params = { ...this.config.getUserData(data) };

            app.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_ACTION}`, params)
              .then((response) => {
                this.tracking(app, 'success', { subscription: true });
                resolve({
                  mode: 'register',
                  response,
                  provider: this.getName(),
                });
              })
              .catch((err) => {
                openModal(ModalInfo, {
                  title: 'Oups. Une erreur est survenue',
                  text: 'Notre service client va prendre le relais. Pouvez-vous nous contacter par email ? ',
                  closeBtnText: 'Obtenir de l’aide',
                  onCloseRedirect: 'https://potagercity.force.com/serviceclient/s/contactsupport',
                });
                reject(err);
              })
              .finally(() => {
                this.endRegisterLoading();
              });
          } else {
            this.endRegisterLoading();
            reject(error);
          }
        });
    });
  }

  startLoginLoading() {
    this.isLoginLoading = true;
  }

  endLoginLoading() {
    this.isLoginLoading = false;
  }

  startRegisterLoading() {
    this.isRegisterLoading = true;
  }

  endRegisterLoading() {
    this.isRegisterLoading = false;
  }
}
