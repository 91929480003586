<template>
  <tunnel-body
    v-if="!unloadCondition"
    class="relative h-full"
    fullscreen
    size="wide">
    <div
      ref="dlpMapPublic"
      class="tunnel-dlp-map-layout__map relative z-10 flex items-center flex-col bg-bianca h-screen max-h-full">
      <dlp-map-public
        :context="context"
        class="delivery-points-page__map"
        @onDlpSelect="onDlpSelect"
        @onSearch="onSearch" />
    </div>

    <div
      class="stack-panels h-full bp768:w-full bp768:absolute bp768:right-0 bp768:top-0 bp768:z-20 bp768:pointer-events-none">
      <router-view
        :back-route="backRoute"
        :context="context"
        :home-route="homeRoute"
        @onDlpSelect="onDlpSelect"
        @onGddSubmit="onGddSubmit"
        @onHomeDeliveryRedirect="onHomeDeliveryRedirect" />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import DlpMapPublic from 'Components/deliveryPoint/map/DlpMapPublic';
import TunnelBody from 'Components/tunnel/TunnelBody';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { getDlpUpdateRoute, getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';
import { DELIVERY_HOME_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  props: {
    isPanel: {
      type: Boolean,
      default: undefined,
    },
  },

  components: {
    TunnelBody,
    DlpMapPublic,
  },

  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
    ]),
    ...mapGetters('tunnel', [
      'getStepRouteByName',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    unloadCondition() {
      // useful to discard infinite loop when panel AND dlpPage open at the same time
      return this.isPanel === false && this.$potagerRoute?.meta?.isPanel;
    },
    backRoute() {
      return this.onList ? undefined : { name: 'delivery-points' };
    },
    homeRoute() {
      return getDlpSearchRoute({
        context: Context.Basket,
        type: DELIVERY_HOME_TYPE,
        currentRoute: this.$potagerRoute,
      });
    },
    onList() {
      return this.$route.name === 'delivery-points';
    },
    getParams() {
      return this.$route?.params;
    },
    getQuery() {
      return this.$route?.query;
    },
    context() {
      if (this.getParams?.context || this.getQuery?.context) {
        return this.getParams?.context || this.getQuery?.context;
      }

      if (this.isLoggedIn && this.isSubscriptionActive) {
        return Context.Basket;
      }

      return Context.SubscriptionBasket;
    },
  },

  methods: {
    onSearch() {
      if (this.$route.params.deliveryPointId) {
        this.$router.push({ name: 'delivery-points' });
      }
    },
    onDlpSelect(dlp) {
      if (dlp) {
        this.$router.push({
          name: 'delivery-point',
          params: {
            deliveryPointRegionId: dlp.regionId,
            deliveryPointId: dlp.id,
          },
        });
      } else {
        this.onSearch();
      }
    },
    onGddSubmit({
      dlpId,
      gddId
    }) {
      this.$notify({
        type: 'success',
        title: 'Choix de livraison enregistrés',
        text: 'Vous pouvez commander et faire votre marché !',
      });

      if (this.isLoggedIn && this.isSubscriptionActive) {
        this.$router.push(getDlpUpdateRoute({
          dlpId,
          gddId,
          currentRoute: this.$potagerRoute,
        }));
      } else {
        this.$router.push(this.getStepRouteByName('boxSlider'));
      }
    },
    onHomeDeliveryRedirect(regionId) {
      this.$router.push(this.getStepRouteByName('deliveryPointHomeSearch', {
        params: { regionId },
      }));
    },
  },

  head: MetaInfosService.generate({
    title: 'Nos Points Relais - Potager City',
    description: 'Profitez de paniers de fruits et légumes frais tout au long de l\'année avec nos points de relais Potager City. Commandez dès aujourd\'hui !',
  }),
};
</script>
