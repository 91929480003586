<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Informations">
    <div class="panel-section text-xs.5">
      <p class="panel-section__p">
        Client n°{{ getUserId }}-{{ getRegionId }}
        <br>
        <template v-if="getRegistrationDate">
          Compte crée le {{ tmsToFormat(getRegistrationDate, 'dd/MM/yyyy') }}
        </template>
      </p>

      <p
        v-if="hasSubscriptionEnterpriseDiscount"
        class="panel-section__p">
        Avantage entreprise : OUI
      </p>
    </div>

    <div class="panel-section">
      <panel-section-navigator :to="{ name: 'mon-compte_identity' }">
        Identité
      </panel-section-navigator>

      <panel-section-navigator :to="{ name: 'mon-compte_email' }">
        E-mail
      </panel-section-navigator>

      <panel-section-navigator :to="{ name: 'mon-compte_password' }">
        Mot de passe
      </panel-section-navigator>

      <panel-section-navigator :to="{ name: 'mon-compte_supprimer' }">
        Supprimer mon compte
      </panel-section-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import Panel from 'Components/panel/Panel';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

export default {

  components: {
    PanelSectionNavigator,
    Panel,
  },

  data: () => ({
    validationClasses: null,
    response: {},
    pending: false,
  }),

  computed: {
    ...mapGetters('user', [
      'getUserId',
      'getRegionId',
      'getRegistrationDate',
      'hasSubscriptionEnterpriseDiscount',
    ]),
  },

  methods: {
    tmsToFormat
  },

  head: MetaInfosService.generate({
    title: 'Informations',
  }),

};
</script>
