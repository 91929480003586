<template>
  <transition
    appear
    name="slide-panel">
    <div
      :class="[
        'panel',
        {
          'panel--large': isLarge,
          'panel--bianca': theme === 'bianca',
          'panel--white': theme === 'white',
          'panel--full-height': fullHeight,
        },
      ]"
      data-container="panel">
      <transition name="slide-top-to-bottom-2rem">
        <slot
          v-if="!isLoading"
          name="before" />
      </transition>

      <div
        v-if="hasHeader || $slots['header']"
        :class="['panel__navigation', headerExtraClasses]">
        <div class="panel__back z-10">
          <potager-button
            v-if="backBtn || backRoute"
            is-circle
            theme="stroke"
            @onClick="goBack">
            <template #icon>
              <icon-arrow-left class="text-xs" />
            </template>
          </potager-button>

          <span
            v-if="$slots['back-btn-title']"
            class="font-semibold ml-3">
            <slot name="back-btn-title" />
          </span>
        </div>

        <transition name="confirm-close">
          <div
            v-if="title || $slots.title || $slots['header']"
            class="panel__header">
            <p
              v-if="title || $slots.title"
              class="panel__navigation__title">
              <slot name="title">
                {{ title }}
              </slot>
            </p>

            <slot
              v-if="!isLoading"
              name="header" />
          </div>
        </transition>

        <potager-button
          v-if="closable"
          class="panel__close"
          is-circle
          theme="stroke"
          @onClick="close">
          <template #icon>
            <icon-close class="text-xs" />
          </template>
        </potager-button>
      </div>

      <div
        ref="content"
        :class="['panel__body', bodyExtraClasses]"
        data-container="true"
        data-scroll="true">
        <transition name="fade">
          <div
            v-if="isLoading"
            class="panel__loader">
            <vue3-lottie
              :animation-data="FruitsLoader"
              :width="100"
              autoplay
              loop />
          </div>
        </transition>

        <slot v-if="!isLoading" />
      </div>

      <transition name="slide-bottom-to-bottom">
        <div
          v-if="$slots['footer']"
          ref="footercontainer"
          :class="[
            'panel__footer',
            {
              'panel__footer--sticky': footerSticky,
            }, footerExtraClasses]">
          <slot name="footer" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconClose from 'Icons/bold/IconClose';

import PotagerButton from 'UI/PotagerButton';

import { KEYCODE_ESCAPE } from 'Classes/Constants';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import COLORS from 'PotagerLogic/Enums/Colors';
import { stuckPage } from 'Classes/utils/ScrollUtils';
import { isEmpty } from 'Classes/utils/BasicUtils';

import { Vue3Lottie } from 'vue3-lottie';
import FruitsLoader from 'Lottie/FruitsLoader';

const { whiteSmoke } = COLORS;

export const props = {
  theme: {
    type: String,
    required: false,
    default: 'bianca',
    values: ['bianca', 'white'],
  },
  backBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  backRoute: {
    type: Object,
    required: false,
    default: null,
  },
  backCallback: {
    type: Function,
    required: false,
    default: null,
  },
  closeRoute: {
    type: Object,
    required: false,
    default: () => {
    },
  },
  isLarge: {
    type: Boolean,
    required: false,
    default: false,
  },
  title: {
    type: String,
    required: false,
    default: null,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  footerSticky: {
    type: Boolean,
    required: false,
    default: true,
  },
  hasHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
  headerExtraClasses: {
    type: [String, Object, Array],
    required: false,
    default: null,
  },
  bodyExtraClasses: {
    type: [String, Object, Array],
    required: false,
    default: null,
  },
  footerExtraClasses: {
    type: [String, Object, Array],
    required: false,
    default: null,
  },
  fullHeight: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    Vue3Lottie,
    IconArrowLeft,
    IconClose,
    PotagerButton,
  },

  props,

  emits: ['panelClosed'],

  data: () => ({
    prevRoute: null,
    colors: { whiteSmoke },
    scrollPosition: {
      left: 0,
      top: 0,
    },
    FruitsLoader,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    closable() {
      // cantHavePanel means we use the Panel component in static mode. Basically in TunnelDlpMapLayout.vue
      // so we don't want the header to be closable because Layout already has a close button
      return this.$potagerRoute?.meta.isPanel || (this.isInAppWebView && !this.$potagerRoute?.meta.cantHavePanel);
    },
  },

  methods: {
    close() {
      if (this.isInAppWebView) {
        this.redirectWebApp();
      }

      this.$emit('panelClosed');

      if (!isEmpty(this.closeRoute)) {
        this.$router.push(this.closeRoute)
          .catch(() => {
          });
      } else {
        this.$router.push({
          path: this.$route.path,
        })
          .catch(() => {
          });
      }
    },
    alternativeClose() {
      if (this.closable) this.close();
    },
    goBack() {
      if (this.backRoute) {
        return this.$router.push(this.backRoute)
          .catch(() => {
          });
      }
      if (this.backCallback && typeof this.backCallback === 'function') return this.backCallback();
      return this.$router.go(-1);
    },
    onKeyUp(evt) {
      if (evt.keyCode === KEYCODE_ESCAPE) this.alternativeClose();
    },
    scrollToTop() {
      this.$refs.content.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },

  mounted() {
    document.addEventListener('keyup', this.onKeyUp);
    this.$events.on('request:closePanel', this.alternativeClose);
    stuckPage();
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
    this.$events.off('request:closePanel', this.alternativeClose);
    stuckPage(false);
  },
};
</script>
