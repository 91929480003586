<template>
  <panel
    :back-route="{ name: 'basket' }"
    title="Codes promo">
    <template #header>
      <p class="text-center">
        Saisissez et sélectionnez les codes promos que vous souhaitez ajouter à votre commande.
      </p>
    </template>

    <coupons-manager :context="Context.Basket" />

    <template #footer>
      <div class="flex items-center">
        <span
          v-if="showCouponTotal"
          class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ getValue }}</b>
        </span>

        <potager-button
          :is-loading="$wait.is('BASKET_COUPON_ACTION')"
          :label="getSimulatedOrderCoupons?.length ? 'Appliquer' : 'Continuer'"
          full-width
          theme="go-green"
          @onClick="confirm" />
      </div>
    </template>
  </panel>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import CouponsManager from 'Components/coupons/CouponsManager';

import { mapGetters } from 'vuex';
import MetaInfosService from 'Classes/services/MetaInfoService';
import { formatPrice } from 'PotagerLogic/Formatting';
import { Context } from 'PotagerLogic/Enums/Context';
import { PRODUCT } from 'Classes/utils/CouponsUtils';

export default {
  components: {
    Panel,
    CouponsManager,
    PotagerButton,
  },

  data() {
    return {
      Context,
    };
  },

  computed: {
    ...mapGetters('basket', [
      'getSimulatedOrderCoupons',
      'getCouponsTotal',
    ]),
    showCouponTotal() {
      const haveProductTypeCoupon = this.getSimulatedOrderCoupons?.some((coupon) => coupon.couponValueType === PRODUCT);
      return !haveProductTypeCoupon && this.getSimulatedOrderCoupons?.length;
    },
    getValue() {
      return `-${formatPrice(this.getCouponsTotal)}`;
    },
  },

  methods: {
    confirm() {
      if (this.getSimulatedOrderCoupons?.length) {
        this.$notify({
          type: 'success',
          title: 'Coupons appliqués',
          text: 'Vos coupons ont bien été appliqués.',
        });
      }

      this.$router.push({ name: 'basket' })
        .catch(() => {
        });
    },
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),
};
</script>
