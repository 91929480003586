<template>
  <div class="product-tools">
    <transition name="fade">
      <div
        v-if="!box.loading"
        class="product-tools__container">
        <div class="product-tools__price">
          <p>
            <span
              v-if="isPromotion && boxBlockedOutPrice"
              class="product-tools__price__blocked-out">
              {{ formatPrice(getBoxBlockedOutPrice) }}
            </span>
            <span
              :class="{
                'product-tools__price__value': true,
                'product-tools__price__value--has-blocked-out-price': boxBlockedOutPrice,
                'product-tools__price__value--promo': isPromotion
              }">
              {{ formatPrice(price, { freeString: 'Offert' }) }}
            </span>
          </p>
          <p class="product-tools__price__details">
            <span class="product-tools__price__details__packaging">
              {{ boxPackagingWeight }}
            </span>
            <span
              v-if="!box.isAssociableToVouchers"
              class="product-tools__price__details__price-by-weight">
              {{ boxPackagingPriceByWeight }}
            </span>
          </p>
        </div>

        <slot name="action">
          <card-box-tools
            :box="box"
            :disabled="box.isAssociableToVouchers"
            :quantity="nbItems"
            can-edit-quantity />
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getBoxBlockedOutPrice } from 'PotagerLogic/Utils/Box/BoxPrices';
import { formatPrice } from 'PotagerLogic/Formatting';
import {
  getProductPackagingPriceByWeight,
  getProductPackagingWeight
} from 'PotagerLogic/Utils/Box/ProductPackaging';

import CardBoxTools from 'Components/cards/UI/CardBoxTools';

export default {
  components: {
    CardBoxTools,
  },

  props: {
    box: {
      type: Object,
      required: true,
    },
    nbItems: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getPriceCategory',
      'isSubscription',
      'canContainMiniBox',
    ]),
    price() {
      return this.isSubscription ? this.box?.price?.subscription : this.box.price?.oneShot;
    },
    boxPackagingWeight() {
      return getProductPackagingWeight(this.box, { format: 'long' });
    },
    boxPackagingPriceByWeight() {
      return getProductPackagingPriceByWeight(this.box, this.getPriceCategory);
    },
    boxBlockedOutPrice() {
      return getBoxBlockedOutPrice(this.box, this.getPriceCategory);
    },
    isDisabled() {
      return this.box.isSoldOut
        || !this.box.deliveryDate.isCurrentWeek
        || this.box.isTypeFormule
        || this.box.isAssociableToVouchers;
    },
    isCouponProduct() {
      return this.box.isAssociableToVouchers;
    },
    isPromotion() {
      return this.box.isInPromotion || this.isCouponProduct;
    },
  },

  methods: {
    formatPrice,
  },
};
</script>
