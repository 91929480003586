<template>
  <div class="panel-section--transparent">
    <potager-form
      id="coupons-manager"
      @onSubmit="addCoupon">
      <potager-input
        v-model="couponCode"
        :constraints="[
          { constraint: 'required', field: 'code' },
          { constraint: 'length', field: 'code', options: { min: 3 } },
        ]"
        :inline-submit-gtm="{ label: 'ajouter un coupon', couponCode }"
        :is-loading="isLoading"
        inline-submit
        name="code"
        placeholder="Code promo"
        reset
        uppercase>
        <template #icon>
          <icon-discount-bubble />
        </template>
      </potager-input>
    </potager-form>
  </div>

  <div
    v-if="getNbCoupon && showCouponsListing"
    class="panel-section--transparent mb-2">
    <b>{{ pluralize(getNbCoupon, 'code promo associé', 'codes promo associés') }} à votre compte</b>

    <coupons-selector
      :context="context"
      :order="order" />
  </div>

  <div
    v-if="$slots.legend"
    class="panel-section mt-4">
    <p class="panel-section__line text-xs.5">
      <slot name="legend" />
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_COUPON_ON_USER_ACTION,
  ADD_COUPON_ON_ORDER_ACTION,
  ADD_COUPON_ON_BASKET_ACTION,
} from 'Stores/types/userActionsTypes';

import { pluralize } from 'PotagerLogic/Formatting';

import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';

import IconDiscountBubble from 'Icons/bold/IconDiscountBubble';

import CouponsSelector from 'Components/coupons/CouponsSelector';
import { Context } from 'PotagerLogic/Enums/Context';

export default {

  components: {
    PotagerInput,
    PotagerForm,
    CouponsSelector,
    IconDiscountBubble,
  },

  data: () => ({
    couponCode: null,
  }),

  props: {
    legend: {
      type: String,
      required: false,
      default: null,
    },
    context: {
      type: String,
      required: false,
      default: Context.User,
      validator: (val) => [Context.Basket, Context.SubscriptionBasket, Context.Subscription, Context.Order, Context.User].includes(val),
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getCoupons',
      'getOrderCoupons',
    ]),
    ...mapGetters('basket', [
      'getSimulatedOrderCoupons',
    ]),
    getCouponsContext() {
      if (!this.order) return [];
      switch (this.context) {
        case Context.Basket:
          return this.getSimulatedOrderCoupons;
        case Context.Order:
          return this.getOrderCoupons(this.order.id);
        default:
          return [];
      }
    },
    getNbCoupon() {
      const diff = this.getCouponsContext?.filter((coupon) => !this.getCoupons?.find((c) => c.id === coupon.id));
      return this.getCoupons?.length + diff?.length;
    },
    addCouponAction() {
      switch (this.context) {
        case Context.Basket:
          return ADD_COUPON_ON_BASKET_ACTION;
        case Context.Order:
          return ADD_COUPON_ON_ORDER_ACTION;
        default:
          return ADD_COUPON_ON_USER_ACTION;
      }
    },
    isLoading() {
      return this.$wait.is(this.addCouponAction);
    },
    showCouponsListing() {
      return this.context !== Context.User;
    },
  },

  methods: {
    pluralize,
    addCoupon() {
      const params = {
        couponCode: this.couponCode?.toUpperCase(),
        ...(this.order ? { orderId: this.order.id } : {}),
      };

      this.$store.dispatch(`user/${this.addCouponAction}`, params)
        .then(() => {
          this.couponCode = '';
          this.$events.emit('request:inputSuccess', {
            field: 'code',
            message: 'Le code promo a été ajouté',
          });
          this.$emit('onSuccess');
        });
    },
  },
};
</script>
