<template>
  <div
    v-if="skeleton || !box"
    class="card-box-price w-1/3 skeleton" />

  <div
    v-else
    class="card-box-price">
    <div class="leading-4 bp640:leading-4 pb-1 flex items-center">
      <span
        :class="{
          'card-box-price__price': true,
          'bg-golden-dream/50 px-1 mr-1': isPromotion,
        }">
        {{ formatPrice(price, { freeString: 'Offert' }) }}
      </span>

      <span
        v-if="blockedOutPrice && isPromotion"
        class="card-box-price__blocked-out-price">
        {{ formatPrice(blockedOutPrice) }}
      </span>
    </div>

    <div class="card-box-price__weight-and-price-by-weight">
      <span
        class="card-box-price__weight"
        v-html="packagingWeight" />

      <span
        v-if="!box.isAssociableToVouchers && priceByWeight"
        class="card-box-price__price-by-weight">
        -&nbsp;<span v-html="priceByWeight" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice } from 'PotagerLogic/Formatting';
import { getBoxBlockedOutPrice, getBoxPrice } from 'PotagerLogic/Utils/Box/BoxPrices';
import { Context, SubscriptionContexts } from 'PotagerLogic/Enums/Context';
import { PriceCategory } from 'PotagerLogic/Enums/Order';
import {
  getProductPackagingPriceByWeight,
  getProductPackagingWeight
} from 'PotagerLogic/Utils/Box/ProductPackaging';

export default {
  props: {
    box: {
      type: Object,
      required: false,
      default: undefined,
    },
    showSubPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: undefined,
    },
    context: {
      type: String,
      default: undefined,
      validator: (value) => Object.values(Context)
        .includes(value),
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getPriceCategory',
    ]),
    computedPriceCategory() {
      return SubscriptionContexts.includes(this.context) ? PriceCategory.priceSubscription : this.getPriceCategory;
    },
    price() {
      return getBoxPrice(this.box, this.computedPriceCategory);
    },
    isPromotion() {
      return this.box.isInPromotion || this.box.isAssociableToVouchers || this.box.highlightedPrice;
    },
    blockedOutPrice() {
      return getBoxBlockedOutPrice(this.box, this.computedPriceCategory);
    },
    packagingWeight() {
      return getProductPackagingWeight(this.box);
    },
    priceByWeight() {
      return getProductPackagingPriceByWeight(this.box, this.computedPriceCategory);
    },
  },

  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss" scoped>
.card-box-price {
  $this: &;
  @apply min-h-[38px];

  &__price {
    @apply font-extrabold leading-tight py-0.5 inline-block text-base;
    .card-v2--small & {
      @apply text-base;
    }
  }

  &__blocked-out-price {
    @apply text-xs line-through text-warm-grey font-semibold;
  }

  &__weight-and-price-by-weight {
    @apply leading-[13px] text-xxs.5;
  }

  &__weight {
    @apply font-semibold;
  }

  &__price-by-weight {
    @apply font-medium text-warm-grey;
  }
}
</style>

<style lang="scss">
.card-box-price {
  .card-v2--landscape & {
    @apply flex flex-col justify-between;
  }

  &__price {
    @apply bp425:text-sm bp425:leading-tight;
  }
}
</style>
